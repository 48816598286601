import Vue from 'vue';
import XkMessage from './src/index.vue';
const XkMessageBox = Vue.extend(XkMessage);
let instance,
    timer = null;
const Message = function (options) {
    let delay = options.delay || 3000;

    instance = new XkMessageBox({
        data: options
    });
    instance.vm = instance.$mount();



    if (timer) {
        clearTimeout(timer)
        document.body.remove(instance.vm.$el);
    }


    document.body.appendChild(instance.vm.$el);
    instance.vm.visible = true;
    console.log(instance.vm.$el,'instance.vm.$el')
    timer = setTimeout(() => {
        instance.vm.visible = false;
        timer = null
    }, delay)

    return instance.vm;
}

export default {
    XkMessage: Message,
    install(Vue) {
        Vue.prototype.$XkMessage = Message;
        Vue.XkMessage = Message;
    }
};
