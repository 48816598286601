<template>
    <div class="menu-item" :style="{
        'background-color': downBc ? downBackgroundColor : ''
    }">
        <div
            ref="menuSel"
            :class="selClasses(item.menuSel)"
            @click="selMenu"
        >
            <div class="icon" :style="{ 'margin-left': `${(level - 1) * 14}px` }">
                <template v-if="!downBc">
                    <span :class="['iconfont', `icon-${item.name}`, 'icon-self-sel']" v-if="item.menuSel && imgurl && !item.menuChildSel"></span>
                    <span :class="['iconfont', `icon-${item.name}`, 'icon-no-sel']" v-else-if="(imgurl || imgurl1) && !item.menuChildSel"></span>
                    <span :class="['iconfont', `icon-${item.name}`, 'icon-child-sel']" v-else-if="!item.menuSel"></span>
                </template>
            </div>
            <div
                v-if="!minniStatus"
                :class="setClasses(item)"
                :style="{'font-weight': item.menuChildSel ? '600' : item.menuSel ? '600' : '400'}"
                :title="item.title"
            >{{ item.title }}</div>
            <div
                style="flex:1;display: flex;align-items: center;"
                v-if="item.children && !minniStatus">
                <i :class="iconClass(item)"></i>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "MenuItem",
    props: {
        item: {
            type: Object
        },
        textColor: {
            type: String
        },
        selBackgroundColor: {
            type: String
        },
        hoverBackgroundColor: {
            type: String
        },
        menOpened: {
            type: Boolean
        },
        downicon: {
            type: Boolean
        },
        downBc: {
            type: Boolean
        },
        downBackgroundColor: {
            type: String
        },
        selTextColor: {
            type: String
        },
        minniStatus: {
            type: Boolean
        },
        themeType: {
            type: String
        },
        level: {
            type: Number,
            default() {
                return 1
            }
        }
    },
    computed: {
        imgurl () {
            // 选中
            let imgurl;
            try {
                imgurl = require(`../../../../assets/images/menu/${this.item.name}__.svg`);
            } catch (err) {
                imgurl = require(`../../../../assets/images/menu/common__.svg`);
            }
            return imgurl;
        },
        imgurl1 () {
            // 默认
            let imgurl1;
            try {
                imgurl1 = require(`../../../../assets/images/menu/${this.item.name}_.svg`);
            } catch (err) {
                imgurl1 = require(`../../../../assets/images/menu/common_.svg`);
            }
            return imgurl1;
        },
        imgurl2 () {
            let imgurl2;
            try {
                imgurl2 = require(`../../../../assets/images/menu/${this.item.name}.svg`);
            } catch (err) {
                imgurl2 = require(`../../../../assets/images/menu/common.svg`);
            }
            return imgurl2;
        }
    },
    mounted() {
        this.setStyle()
    },
    methods: {
        /**
         * @Description: 说明
         * @Author: 作者
         * @Date: 2024-08-16 15:32:31
         * @param {*} item
         */        
         selClasses(status) {
            const name = 'menu-sel';
            return [
                name,
                {['current']: status}
            ]
        },
        /**
         * @Description: 说明
         * @Author: 作者
         * @Date: 2024-08-16 15:58:56
         * @param {*} item
         */        
        setClasses(item) {
            const name = 'text';
            return [
                name,
                { ['sel-item']: (item.menuSel && !item.menuChildSel) },
                { ['no-sel-item']: (!item.menuSel && item.menuChildSel) },
            ]
        },
        /**
         * @Description: icon颜色
         * @Author: 武东超
         * @Date: 2024-08-16 16:11:56
         * @param {*} item
         */    
        iconClass(item) {
            const name = 'arrow-right el-icon-caret-bottom'
            return [
                name,
                {['open']: item.open},
                {['sel-icon']: (item.menuSel && !item.menuChildSel)},
                {['sel-parent-icon']: (!item.menuSel && item.menuChildSel)},
                {['no-sel-icon']: (!item.menuSel && !item.menuChildSel)},
            ]
        },    
        menuItemRightIcon (item) {
            let color;
            if (item.menuSel && !item.menuChildSel) {
                color = '#ffffff';
            } else {
                color = '#6D7073';
            }

            if (!item.menuSel && item.menuChildSel) {
                color = '#3C7FFF';
            }

            return color;
        },
        setStyle() {
          this.$nextTick(() => {
            this.$refs.menuSel.style.setProperty('--hoverColor', this.hoverBackgroundColor)
          })
        },
        selMenu () {
            let parent = this.$parent;
            let type = true;
            while(parent && type) {
                if (parent.compName == 'MENU') {
                    type = false;
                    parent.selMenu(this.item)
                }
                parent = parent.$parent;
            }
        }
    }
}
</script>

<style scoped>
    @import url('../../../css/menu.css');
</style>
