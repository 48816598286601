<template>
    <transition
        name="dialog-fade"
        @after-enter="afterEnter"
        @after-leave="afterLeave"
    >
        <div v-show="visible" id="J_xk-message" class="xk-message">
            <div class="xk-message-wrapper">
                <div class="close-icon">X</div>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
    name: "XkMessage",
    data () {
        return {
            timer: null,
            visible: false
        }
    },
    mounted() {
        if (this.visible) {
            this.init()
        }
    },
    methods: {
         afterEnter() {
            this.$emit("afterEnter");
         },
         afterLeave() {
            this.$emit("afterLeave");
        },
    },
};
</script>
<style lang="scss" scoped>
    .xk-message {
        position: fixed;
        z-index: 9999;
        left: 50%;
        top: 10%;
        transform: translate(-50%, 0%);
        -webkit-animation: message 1s linear;
        animation: message 1s linear;
        pointer-events: none;
        border: 1px solid red;

        .xk-message-wrapper {
            width: 330px;
            height: 70px;
            background: #FFFFFF;
            border-radius: 4px;

            .close-icon {
                float: right;
                padding: 14px 10px 0 0;
                font-size: 12px;
                color: #333333;
            }
        }

        .xk-message-wrapper.info {

        }


    }

    @keyframes message {
        0% {
            top: -20%;
            opacity: 0.3;
        }
        100% {
            top: 10%;
            opacity: 1;
        }
    }
</style>
