<template>
    <div
        class="menu"
        :style="{
            'width': menuWidth,
            'background-color': backgroundColor,
        }"
        @mouseenter="handlerMouseenter"
        @mouseleave="handlerMouseleave"
        >
        <logo
            v-if="logoStatus"
            :minniStatus="minniStatus">
            <template #logo>
                <img :src="logoUrl" alt="logo"  />
            </template>
            <template #text>
                云校园运营管理系统
            </template>
        </logo>
        <div class="nav-links" :style="{ 'padding-top': navlinks }">
            <template
                v-for="item of user_router"
            >
                <menu-item
                    v-if="!item.children"
                    :key="item.id"
                    :item="item"
                    :textColor="textColor"
                    :selTextColor="selTextColor"
                    :selBackgroundColor="selBackgroundColor"
                    :hover-background-color="hoverBackgroundColor"
                    :minniStatus="minniStatus"
                    @selMenu="selMenu"
                >
                </menu-item>
                <menu-re
                    v-else
                    :key="item.id"
                    :data="item"
                    :selBackgroundColor="selBackgroundColor"
                    :hover-background-color="hoverBackgroundColor"
                    :textColor="textColor"
                    :selTextColor="selTextColor"
                    :downBackgroundColor="downBackgroundColor"
                    :minniStatus="minniStatus"
                    :themeType="themeType"
                    ></menu-re>
                </template>
            </div>
            <div
                v-show="folded"
                class="menu-close-btn1"
                @click="menuClose"
            >
                <i class="el-icon-caret-left" :class="{ 'miniStatus': minniStatus }"></i>
            </div>
        </div>
</template>
<script>
import Logo from './Logo.vue'
import MenuItem from './MenuItem.vue'
import MenuRe from './MenuRe.vue'
import { circulationTree, getFrameUrl, selTreeRoute, treeFindPath, selTreeParentRoute, treeRouteOpen } from "../../../libs/menu.js";
import * as dd from "dingtalk-jsapi";
export default {
    name: "XkMenu",
    components: {
        Logo,
        MenuItem,
        MenuRe
    },
    props: {
        width: {
            type: String,
            default: function () {
                return '200px'
            }
        },
        userRouters: {
            type: Array
        },
        backgroundColor: {
            type: String
        },
        selBackgroundColor: {
            type: String,
            default: function () {
                return '#3C7FFF'
            }
        },
        hoverBackgroundColor: {
           type: String,
        },
        downBackgroundColor: {
            type: String
        },
        textColor: {
            type: String
        },
        selTextColor: {
            type: String,
            default: function () {
                return '#ffffff'
            }
        },
        logoStatus: {
            type: Boolean
        },
        navlinks: {
            type: String,
            default: function () {
                return '0px'
            }
        },
        themeType: {
          type: String
        },
        sideBarWd: {
            type: String
        },
        staticRouteMenu: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data () {
        return {
            inDingTalk: false, // 是否是钉钉环境
        }
    },
    computed: {
        logoUrl () {
            return require('../../../icon/logo.png');
        },
        menuCloseUrl () {
            return require('../../../icon/menuClose.png');
        },
        addId () {
            return this.$route.query.id;
        },
        name() {
            return this.$route.query.name;
        },
    },
    created () {
        this.inDingTalk = dd.env.platform !== "notInDingTalk";
        console.log('this.inDingTalk当前登录环境是否是钉钉',this.inDingTalk);
        this.init();
    },
    data () {
        return {
            compName: "MENU",
            user_router: [],
            menuWidth: '200px',
            minniStatus: false,
            setWidth: '42px',
            lastTime: 0,
            folded: false
        }
    },
    methods: {
        // 初始化树形结构
        init () {
            const _this = this;
            this.initOptions();
            // setTimeout()
            _this.bindEvent()
        },
        bindEvent () {
            const _this = this;
            this.user_router = circulationTree(this.userRouters, _this);

            if (this.user_router.length > 0) {
                console.log(this.user_router,this.$route,'this.user_router-app')
                selTreeRoute(this.user_router, this.$route, _this)
                treeRouteOpen(this.user_router, this.$route, _this, this.staticRouteMenu)
                this.getParentSelTree (this.$route)
            }
        },
        initOptions () {
            if (this.menuWidth == this.setWidth) {
                return
            }
            this.menuWidth = this.width;
        },
        selMenu (tar) {
            const _this = this;
            console.log("[selMenu]:", tar)
            if (tar.children) {
                this.menuOpen();
                tar.open = !tar.open;
            } else {
                /**
                 * isFrame   是否外链  1外链  0路由
                 */
                if (tar.isFrame === 1 && tar.linkJump != 2) {
                    this.handleFrameUrl(tar)
                }
                this.$router.push({
                    name: tar.name,
                    query: {
                        id: this.addId,
                        name: this.name
                    }
                })
                // this.$eventDispatch('setPageInit')
            }
            selTreeRoute(this.user_router, tar, _this)
            this.getParentSelTree(tar)
            this.clearOtherOptions()
        },
        getParentSelTree (tar) {
            const _this = this;
            let parentArr = [];
            parentArr = treeFindPath(this.user_router, data => data.name === tar.name, "menuId");
            parentArr.pop()
            // 静态路由
            if (tar.meta && tar.meta.selMenuId) {
                parentArr = this.staticRouteMenu.filter(item => {
                    return item.menuId === tar.meta.selMenuId
                })[0].parentArr;
            }

            selTreeParentRoute(this.user_router, parentArr, _this)
        },
        clearOtherOptions () {
            localStorage.setItem('J_CoworkingOAApproval', '{}')
        },
        handleFrameUrl(tar) {
            if (tar.frameUrl) {
                let frameUrl = getFrameUrl(tar)
                let promise = new Promise((resolve, reject) => {
                    resolve(frameUrl)
                })
                promise.then((res) => {
                    if (this.inDingTalk) {
                        // 是钉钉环境跳转
                        let urlEncodeedParam = encodeURIComponent(res)
                        let newUrl = `https://applink.dingtalk.com/page/link?url=${urlEncodeedParam}&target=workbench`;
                        let a = document.createElement("a")
                        a.href = newUrl
                        document.body.appendChild(a)
                        a.click()
                        document.body.removeChild(a)
                    } else {

                        // 不是钉钉环境的跳转
                        const _window = window.open();
                        console.log(res,'跳转地址')
                        if (_window) {
                            _window.location.href = res
                        } else {
                            const a = document.createElement("a")
                            a.href = res
                            document.body.appendChild(a)
                            a.click()
                            document.body.removeChild(a)
                        }
                    }
                })
            } else {
                this.$router.push({
                    name: 'Building',
                })
            }
        },
        menuClose () {
            if (this.menuWidth !== this.setWidth) {
                this.user_router.forEach((item) => {
                    item.open = false
                })
                this.minniStatus = true;
                this.menuWidth = this.setWidth;
            } else {
                this.minniStatus = false
                this.menuWidth = this.width;
            };
            this.$emit('navBarFolded', this.minniStatus)
        },
        menuOpen() {
            this.menuWidth = this.width;
            this.minniStatus = false;
            this.$emit('navBarFolded', this.minniStatus)
        },
        /**
         * @Description: 鼠标滑入滑出menu元素
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-07-21 09:08:53
         */
        handlerMouseenter () {
            this.folded = true;
        },
        handlerMouseleave () {
            this.folded = false;
        },
        changeOpen (newVal) {
            console.log('newVal')
            let sideBarLeft = Number(newVal.substring(0, newVal.length - 2)),
                sideBarWd = Number(this.width.substring(0, this.width.length -2));

                if (sideBarLeft > sideBarWd) {
                    this.minniStatus = false
                    this.menuWidth = this.width;
                } else {
                    this.user_router.forEach((item) => {
                        item.open = false
                    })
                    this.minniStatus = true;
                    this.menuWidth = this.setWidth;
                }
        }
    },
    watch: {
        '$route': {
            handler (newVal) {
                console.log(newVal,'newVal-menu变化')
                this.init()
            },
            deep: true
        },
        'sideBarWd': {
            handler (newVal) {
                this.changeOpen(newVal)
            },
            deep: true
        }
    }
}
</script>
<style lang="scss" scoped>
    .menu {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 0 6px;
        transition: all 0.3s ease;
    }
    /*webkit内核*/
    .menu::-webkit-scrollbar {
        width:0px;
        height:0px;
    }
    /* o内核 */
    .menu .-o-scrollbar{
        -moz-appearance: none !important;
        background: rgba(0,255,0,0) !important;
    }
    /*IE10,IE11,IE12*/
    .menu{
        -ms-scroll-chaining: chained;
        -ms-overflow-style: none;
        -ms-content-zooming: zoom;
        -ms-scroll-rails: none;
        -ms-content-zoom-limit-min: 100%;
        -ms-content-zoom-limit-max: 500%;
        -ms-scroll-snap-type: proximity;
        -ms-scroll-snap-points-x: snapList(100%, 200%, 300%, 400%, 500%);
        -ms-overflow-style: none;
        overflow: auto;
    }

    .nav-links {
        height: 100%;
        padding: 0px 0px 0px 0px;
        flex: 1;
        overflow: auto;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE 10+ */
    }
    ::-webkit-scrollbar {
        display: none; /* Chrome Safari */
    }

    .menu-close-btn {
        position: absolute;
        top: 50%;
        right: 0;
        cursor: pointer;
    }
    .menu-close-btn1 {
        position: absolute;
        right: 0;
        cursor: pointer;
        text-align: center;
        /* top: calc(50% + 20px);
        background-color: #fafcff;
        width: 18px;
        height: 48px;
        line-height: 48px;
        border-radius: 8px 0 0 8px; */
        top: calc(50% - 60px);
        background-color: #000000;
        width: 20px;
        height: 73px;
        line-height: 73px;
        opacity: 0.2;
        border-radius: 10px;
    }
    /* .menu-close-btn1::before,.menu-close-btn1::after{
        position: absolute;
        right: 0;
        content: '';
        width: 30px;
        height: 40px;
        border-radius: 100%;
        box-shadow: 0 0 0 40px #fafcff; // 使用box-shadow不影响尺寸
    }
    .menu-close-btn1::after{
        top: -40px;
        clip-path: inset(70% 0 -1px 60%);
    }
    .menu-close-btn1::before{
        bottom: -40px;
        clip-path: inset(-1px 0 70% 60%);
    } */
    .el-icon-caret-left {
        transition: all .3s;
        font-size: 18px;
        font-weight: bold;
        color: #FFFFFF;
    }
    .miniStatus {
        transform: rotate(180deg);
    }

    .close {
        width: 76px;
    }
</style>
