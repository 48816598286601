<template>
    <div class="logo-wrapper">
        <div class="logo-slider">
            <div class="logo">
                <slot name="logo"></slot>
            </div>
            <div class="text" v-if="!minniStatus">
                <slot name="text"></slot>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Logo",
    props: {
        minniStatus: {
            type: Boolean
        }
    }
}
</script>
<style scoped>
    .logo-wrapper {
        width: 100%;
        height: 96px;
    }

    .logo-slider {
        width: calc(100% - 20px);
        margin: 0 auto;
        border-bottom: 1px solid rgba(250, 250, 250, .2);
    }
    .logo-slider .logo {
        width: 42px;

    }

    .logo-slider .logo img {
        width: 100%;
    }

    .logo-slider .text {

        font-size: 20px;
        color: #ffffff;
        text-align: center;
        margin-bottom: 10px;
        overflow: hidden;
    }

</style>
