<template>
    <div>
        <menu-item
            :item="data"
            :textColor="textColor"
            :selTextColor="selTextColor"
            :level="level_"
            :selBackgroundColor="selBackgroundColor"
            :hover-background-color="hoverBackgroundColor"
            :downBackgroundColor="downBackgroundColor"
            :downBc="downBc"
            :minniStatus="minniStatus"
            ></menu-item>
            <el-collapse-transition>
            <div class="drow" v-if="data.open">
                <template v-for="c of data.children">
                    <menu-item :key="c.id"
                        v-if="!c.children"
                        :item="c"
                        :downBc="true"
                        :level="level_ + 1"      
                        :selBackgroundColor="selBackgroundColor"
                        :downBackgroundColor="downBackgroundColor"
                        :hover-background-color="hoverBackgroundColor"
                        :textColor="textColor"
                        :selTextColor="selTextColor"
                        :minniStatus="minniStatus"
                        :theme-type="themeType"
                        ></menu-item>
                    <menu-re
                        v-else
                        :data="c"
                        :key="c.id"
                        :downBc="true"
                        :level="level_"
                        :selBackgroundColor="selBackgroundColor"
                        :hover-background-color="hoverBackgroundColor"
                        :downBackgroundColor="downBackgroundColor"
                        :textColor="textColor"
                        :selTextColor="selTextColor"
                        :minniStatus="minniStatus"
                        :theme-type="themeType"
                        ></menu-re>
                </template>
            </div>
            </el-collapse-transition>
    </div>
</template>
<script>
import MenuItem from './MenuItem.vue'
export default {
    name: "MenuRe",
    components: {
        MenuItem
    },
    props: {
        data: Object,
        textColor: {
            type: String
        },
        selTextColor: {
            type: String
        },
        selBackgroundColor: {
            type: String
        },
        hoverBackgroundColor: {
            type: String
        },
        downBackgroundColor: {
            type: String
        },
        downBc: {
            type: Boolean
        },
        minniStatus: {
            type: Boolean
        },
        themeType: {
            type: String
        },
        level: {
            type: Number,
            default() {
                return 0
            }
        }
    },
    data() {
        return {
            level_: 0
        }
    },
    created() {
        this.level_ = this.level
        this.level_++
    }
}
</script>
<style scoped>
    .fade-enter-active, .fade-leave-active {
        opacity: 1;
        -ms-transform: scaleY(1);
        transform: scaleY(1);
        transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1) 0.1s,
        opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1) 0.1s;
        -ms-transform-origin: center top;
        transform-origin: center top;

    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
        -ms-transform: scaleY(0);
        transform: scaleY(0);
    }
</style>
